import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const state = {
  isForceLogout: false,
  user: {
    lang: null,
    brand_id: null,
    name: null,
    role: null,
    email: null,
    uuid: null,
    maskedUUID: null,
    mail_service: [],
  },
  license: {
    brand_name: null,
    end_time: null,
    total_quota: 0,
    remain_quota: 0,
    unlimited_quota: false,
    license_account: 0,
    license_remain_account: 0,
    api_service: 0,
    dataset: null,
    dimensions: [],
    scenario: [],
    display_credit_risk: null,
  },
  mail_service: {
    remain_quota_notifications: {
      is_enabled: true,
      recipients: [],
    },
    license_expiration_reminder: {
      is_enabled: true,
      recipients: [],
    },
    not_enough_space: {
      is_enabled: true,
      recipients: [],
    },
    table_update: {
      is_enabled: true,
      recipients: [],
    },
    query: {
      is_enabled: false,
      recipients: [],
    },
    app_key_expiration_reminder: {
      is_enabled: true,
      recipients: [],
    },
    dfs_mapping_report: {
      is_enabled: false,
      recipients: [],
    },
    dfs_psi_report: {
      is_enabled: false,
      recipients: [],
    },
    delete_log_weekly_report: {
      is_enabled: false,
      recipients: [],
    },
  },
  version: {
    data: null,
    service: null,
  },
  system: {
    remain_disk: '',
  },
  enableMaskUUID: false,
  enableMaskAppKey: false,
};

const mutations = {
  setForceLogout(state, status) {
    state.isForceLogout = status;
  },
  setUserLang(state, lang) {
    state.user.lang = lang;
  },
  setUserName(state, name) {
    state.user.name = name;
  },
  setUserData(state, { userData }) {
    state.user.brand_id = userData.brand_id;
    state.user.name = userData.name;
    state.user.role = userData.role;
    state.user.email = userData.email;
    state.user.uuid = userData.uuid;
    state.user.maskedUUID = userData.uuid.replace(/./g, '*');
  },
  setMailServiceData(state, { msData }) {
    state.mail_service[msData.type].is_enabled = msData.is_enabled;
    state.mail_service[msData.type].recipients = msData.recipients;
  },
  setMailServiceStatus(state, { type, is_enabled }) {
    state.mail_service[type].is_enabled = is_enabled;
  },
  setMailServiceRecipients(state, { type, recipients }) {
    state.mail_service[type].recipients = recipients;
  },
  setLicenseUserRemainAccount(state, remain_quota) {
    state.license.license_remain_account = remain_quota;
  },
  setLicenseData(state, { licenseData }) {
    state.license.brand_name = licenseData.name;
    state.license.end_time = licenseData.etime;
    state.license.total_quota = licenseData.total_quota;
    state.license.remain_quota = licenseData.remain;
    state.license.unlimited_quota = licenseData.unlimited_quota;
    state.license.license_account = licenseData.lic_acc;
    state.license.license_remain_account = licenseData.lic_remain_acc;
    state.license.api_service = licenseData.api_service;
    state.license.dataset = licenseData.dataset;
    state.license.dimensions = licenseData.dimensions;
    state.license.quota_alert_level = licenseData.quota_alert_level;
    state.license.scenario = licenseData.scenario;
    state.license.display_credit_risk = licenseData.display_credit_risk;
  },
  setLicesneQuotaAlertLevel(state, quota_alert_level) {
    state.license.quota_alert_level = quota_alert_level;
  },
  setLicenseRemainQuota(state, { quotaData }) {
    state.license.remain_quota = quotaData.remain_quota;
    state.license.unlimited_quota = quotaData.unlimited_quota;
  },
  setSystemVersion(state, { versionData }) {
    state.version.data = versionData.data;
    state.version.service = versionData.service;
  },
  setRemainDisk(state, remain_disk) {
    state.system.remain_disk = remain_disk;
  },
  setEnableMaskUUID(state, status) {
    state.enableMaskUUID = status;
  },
  setEnableMaskAppKey(state, status) {
    state.enableMaskAppKey = status;
  },
};

export default new Vuex.Store({
  state,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 3, path: '/' }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  mutations,
});
