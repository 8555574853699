import Cookies from 'js-cookie';
import Vue from 'vue';
import VueRouter from 'vue-router';

import { apiCheckUserStatus } from './api.js';
import ApiDocs from './components/ApiDocs.vue';
import AppHeader from './components/base/header.vue';
import AppSidebar from './components/base/sidebar.vue';
import ContactUs from './components/ContactUs.vue';
import DatasetUpdate from './components/DatasetUpdate.vue';
import Faq from './components/Faq.vue';
import Forbidden from './components/Forbidden.vue';
import Home from './components/Home.vue';
import LicenseActive from './components/LicenseActive.vue';
import LicenseUpdate from './components/LicenseUpdate.vue';
import NumReport from './components/NumReport.vue';
import SearchSingleResult from './components/SearchSingleResult.vue';
import TableUpdate from './components/TableUpdate.vue';
import UnsupportBrowser from './components/UnsupportBrowser.vue';
import UserAccount from './components/UserAccount.vue';
import UserSearchLogReport from './components/UserSearchLogReport.vue';
import UserForgetPassword from './components/UserForgetPassword.vue';
import UserLogin from './components/UserLogin.vue';
import UserLogout from './components/UserLogout.vue';
import UserManage from './components/UserManage.vue';
import UserQuota from './components/UserQuota.vue';
import UserRegister from './components/UserRegister.vue';
import UserSetPassword from './components/UserSetPassword.vue';
import i18n from './lang/lang';
import store from './store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'query',
    component: Home,
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: Home,
    },
    meta: {
      header: true,
      sidebar: true,
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/license/active',
    component: LicenseActive,
    components: {
      sidebar: AppSidebar,
      default: LicenseActive,
    },
    meta: {
      requiresAuth: false,
      layout: 'OnlySidebar',
    },
  },
  {
    path: '/license/update',
    component: LicenseUpdate,
    components: {
      sidebar: AppSidebar,
      default: LicenseUpdate,
    },
    meta: {
      requiresAuth: false,
      layout: 'OnlySidebar',
    },
  },
  {
    path: '/user/register',
    components: {
      sidebar: AppSidebar,
      default: UserRegister,
    },
    meta: {
      requiresAuth: false,
      layout: 'OnlySidebar',
    },
  },
  {
    path: '/user/login',
    components: {
      sidebar: AppSidebar,
      default: UserLogin,
    },
    meta: {
      requiresAuth: false,
      layout: 'OnlySidebar',
    },
  },
  {
    path: '/user/forget_pwd',
    component: UserForgetPassword,
    meta: {
      requiresAuth: false,
      layout: 'BothNone',
    },
  },
  {
    path: '/user/reset_pwd',
    component: UserSetPassword,
    meta: {
      header: false,
      sidebar: false,
      requiresAuth: false,
      layout: 'BothNone',
    },
  },
  {
    path: '/user/manage',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: UserManage,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/user/account',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: UserAccount,
    },
    meta: {
      header: true,
      sidebar: true,
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/user/quota',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: UserQuota,
    },
    meta: {
      header: true,
      sidebar: true,
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/user/search_log_report',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: UserSearchLogReport,
    },
    meta: {
      header: true,
      sidebar: true,
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/user/logout',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: UserLogout,
    },
    meta: {
      header: true,
      sidebar: true,
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/search/:state/:number',
    name: 'SearchSingleNumber',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: SearchSingleResult,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/num_report',
    name: 'report',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: NumReport,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/api_docs',
    name: 'api',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: ApiDocs,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: Faq,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/contact_us',
    name: 'contact_us',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: ContactUs,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/dataset_update',
    name: 'dataset_update',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: DatasetUpdate,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/table_update',
    name: 'table_update',
    components: {
      header: AppHeader,
      sidebar: AppSidebar,
      default: TableUpdate,
    },
    meta: {
      requiresAuth: true,
      layout: 'Both',
    },
  },
  {
    path: '/unsupport',
    component: UnsupportBrowser,
    meta: {
      header: false,
      sidebar: false,
      requiresAuth: false,
      layout: 'BothNone',
    },
  },
  {
    path: '/forbidden',
    component: Forbidden,
    meta: {
      header: false,
      sidebar: false,
      requiresAuth: false,
      layout: 'BothNone',
    },
  },
  {
    path: '**',
    redirect: '/user/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE,
  routes,
});

history.pushState(null, null, document.URL);
window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL);
});

router.beforeEach((to, from, next) => {
  const licenseDataset = store.state.license.dataset;
  if (to.name !== undefined) {
    const purchasedFunc = process.env.VUE_APP_SIDEBAR.split(', ');
    if (to.name === 'SearchSingleNumber') {
      if (purchasedFunc.includes('query')) {
        next();
      }
    } else if (
      ['query', 'report'].includes(to.name) &&
      purchasedFunc.includes('query') &&
      purchasedFunc.includes('report')
    ) {
      if (to.name === 'query' && ['das', 'dfs'].includes(licenseDataset)) {
        next({ name: 'report' });
      } else if (to.name === 'report' && ['bank', 'rental', 'identity_report'].includes(licenseDataset)) {
        next({ name: 'query' });
      }
    } else {
      if (purchasedFunc.includes(to.name)) {
        next();
      } else {
        next({ name: purchasedFunc[0] });
      }
    }
  }
  // 判斷語言的順序
  // 1. user 自訂的語言 (Store)
  // 2. Browser 的語言
  // 3. Default (en)
  let browserLang = navigator.language || navigator.userLanguage;
  const userLang = store.state.user.lang;
  const supportLangs = process.env.VUE_APP_LANGS.split(', ');
  const defaultLang = process.env.VUE_APP_DEFAULT_LANG;

  if (supportLangs.includes(userLang)) {
    i18n.locale = userLang;
  } else {
    if (['zh', 'en', 'ja', 'zh-TW', 'en-US', 'ja-JP'].includes(browserLang)) {
      if (browserLang === 'zh' || browserLang === 'zh-TW') {
        browserLang = 'zh_TW';
      } else if (browserLang === 'en' || browserLang === 'en-US') {
        browserLang = 'en_US';
      } else if (browserLang === 'ja' || browserLang === 'ja-JP') {
        browserLang = 'ja_JP';
      }
    }

    if (supportLangs.includes(browserLang)) {
      store.commit('setUserLang', browserLang);
      i18n.locale = browserLang;
    } else {
      store.commit('setUserLang', defaultLang);
      i18n.locale = defaultLang;
    }
  }

  const isChrome = /chrome/.test(navigator.userAgent.toLowerCase());
  if (to.path === '/unsupport' && isChrome) {
    next({ path: '/' });
  } else if (to.path !== '/unsupport' && isChrome) {
    if (
      to.matched.some((record) => {
        return record.meta.requiresAuth;
      })
    ) {
      if (process.env.VUE_APP_VERSION === 'offline') {
        if (Cookies.get('session')) {
          next(); // 往下繼續執行
        } else {
          next({ path: '/user/login' });
        }
      } else {
        apiCheckUserStatus()
          .then(function (response) {
            const res = response.data;
            if (res.msg === 'OK') {
              next(); // 往下繼續執行
            } else {
              next({ path: '/user/login' });
            }
          })
          .catch(function () {
            next({ path: '/user/login' });
          });
      }
    } else {
      next(); // 往下繼續執行
    }
  } else if (to.path === '/unsupport' && !isChrome) {
    next(); // 往下繼續執行
  } else {
    next({ path: '/unsupport' });
  }
});

export default router;
