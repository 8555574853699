<template>
  <div class="main-button download-btn" :disabled="isLoading" @click="clickDownLoad(item)">
    {{ $t('download') }}
  </div>
</template>

<script>
export default {
  name: 'DownLoadBottom',
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    downLoadApi: {
      type: Function,
      default: () => { },
    },
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    clickDownLoad: function (item) {
      const $ = this;
      $.isLoading = true;

      $.downLoadApi(item)
        .then(function (response) {
          const res = response.data;
          if (res.msg === 'OK') {
            const link = document.createElement('a');
            link.href = res.res.download_url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            const apiError = $.$i18n.t(`api_error.${res.msg}`);
            alert(apiError);
          }
        })
        .catch(function (error) {
          alert(error);
        }).finally(() => {
          setTimeout(()=>{
            $.isLoading = false;
          }, 1000);
        });
    },
  },
}
</script>

<style scoped>
.download-btn {
  cursor: pointer;
  height: 20px;
  line-height: 18px;
  font-size: 14px;
  text-align: center;
}
</style>
