<template>
  <div v-if="isLogin" class="sidebar console">
    <div class="brand__info">
      <div v-if="!unlimitedQuota" class="brand__info sidebar__remain">
        {{ $store.state.license.remain_quota | commaFormat }}
      </div>
      <div v-if="!unlimitedQuota" class="brand__info sidebar__text">{{ $t('side_bar_info_remain_credit_quota') }}</div>
      <div class="brand__info sidebar__name" v-bind:class="{ 'mt-25': !unlimitedQuota }">
        {{ $store.state.license.brand_name }}
      </div>
      <div class="brand__info sidebar__etime mt-5">
        {{ $store.state.license.end_time | etimeFormat }}
      </div>

      <div v-if="version === 'offline'" class="brand__info sidebar__disk mt-20">
        {{ $t('side_bar_info_remain_space', { remain_space: $store.state.system.remain_disk }) }}
      </div>
    </div>
    <hr class="sidebar__hr mt-40 mb-20" />
    <!-- Query -->
    <div v-if="sidebar.includes('query') && isQueryValidDataset()" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/' || $route.name == 'SearchSingleNumber' }">
      <router-link tag="div" to="/" class="ml-40" @click.native="checkNowPage" exact>{{
        $t('side_bar_menu_num_search')
        }}</router-link>
    </div>
    <!-- Report -->
    <div v-if="sidebar.includes('report') && isReportValidDataset() && isOffline()" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/num_report' }">
      <router-link tag="div" to="/num_report" class="ml-40" exact>{{
        $t('side_bar_menu_model_num_search')
        }}</router-link>
    </div>
    <!-- API Docs -->
    <div v-if="
      sidebar.includes('api') &&
      ($store.state.license.dataset === 'bank' || $store.state.license.dataset === 'rental')
    " class="menu" v-bind:class="{ 'active mt-20 mb-20': $route.path == '/api_docs' }">
      <router-link tag="div" to="/api_docs" class="ml-40">{{ $t('side_bar_menu_api') }}</router-link>
    </div>
    <!-- User Management -->
    <div v-if="sidebar.includes('member') && $store.state.user.role === 'admin'" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/user/manage' }">
      <router-link tag="div" to="/user/manage" class="ml-40">{{ $t('side_bar_menu_member') }}</router-link>
    </div>
    <!-- Settings (role is user) -->
    <div v-if="sidebar.includes('settings') && $store.state.user.role === 'user'" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/user/account' }">
      <router-link tag="div" to="/user/account" class="ml-40">{{ $t('side_bar_menu_account_info') }}</router-link>
    </div>
    <!-- Account Information -->
    <div v-if="sidebar.includes('settings', 'quota_history', 'search_log_report') && $store.state.user.role === 'admin'"
      class="menu"
      v-bind:class="{ 'has-sub-active mt-20': $route.path == '/user/quota' || $route.path == '/user/account' || $route.path == '/user/search_log_report' }"
      v-on:click="show = !show">
      <span class="ml-40">{{ $t('side_bar_menu_account') }}</span>
      <span class="submenu__icon icon-arrow-up" v-if="show"></span><span class="submenu__icon icon-arrow-down"
        v-else></span>
    </div>
    <!-- Daily Queries (for role is Admin) -->
    <div v-if="sidebar.includes('quota_history') && show && $store.state.user.role === 'admin'" class="submenu"
      v-bind:class="{ active: $route.path == '/user/quota', 'has-bg': ['/user/account', '/user/search_log_report'].includes($route.path) }">
      <router-link tag="div" to="/user/quota" class="ml-60">{{ $t('side_bar_menu_account_search_cnt') }}</router-link>
    </div>
    <!-- Setting (role is Admin) -->
    <div v-if="sidebar.includes('settings') && show && $store.state.user.role === 'admin'" class="submenu"
      v-bind:class="{ 'active': $route.path == '/user/account', 'has-bg': ['/user/quota', '/user/search_log_report'].includes($route.path) }">
      <router-link tag="div" to="/user/account" class="ml-60">{{ $t('side_bar_menu_account_info') }}</router-link>
    </div>
    <!-- Search Log Report (role is Admin) -->
    <div v-if="sidebar.includes('search_log_report') && show && $store.state.user.role === 'admin'" class="submenu"
      v-bind:class="{ 'active mb-20': $route.path == '/user/search_log_report', 'has-bg mb-20': ['/user/quota', '/user/account'].includes($route.path) }">
      <router-link tag="div" to="/user/search_log_report" class="ml-60">{{ $t('side_bar_menu_search_log_report')
        }}</router-link>
    </div>
    <!-- FAQ -->
    <div v-if="sidebar.includes('faq') && isQueryValidDataset()" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/faq' }">
      <router-link tag="div" to="/faq" class="ml-40">{{ $t('side_bar_menu_faq') }}</router-link>
    </div>
    <!-- Contact US -->
    <div v-if="sidebar.includes('contact_us') && (isQueryValidDataset() || isOnline())" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/contact_us' }">
      <router-link tag="div" to="/contact_us" class="ml-40">{{ $t('side_bar_menu_contactus') }}</router-link>
    </div>
    <!-- Upload Dataset -->
    <div v-if="sidebar.includes('dataset_update')" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/dataset_update' }">
      <router-link tag="div" to="/dataset_update" class="ml-40">{{ $t('side_bar_menu_dataset_update') }}</router-link>
    </div>
    <!-- Upload Customer Table -->
    <div v-if="sidebar.includes('table_update')" class="menu"
      v-bind:class="{ 'active mt-20 mb-20': $route.path == '/table_update' }">
      <router-link tag="div" to="/table_update" class="ml-40">{{
        $t('side_bar_menu_customized_data_upload')
        }}</router-link>
    </div>
    <!-- Service Version & Data Version (Offline) -->
    <div v-if="version === 'offline'" class="mt-60 version">
      <div>{{ $t('side_bar_info_dataset_version', { data_ver: systemDataVersion }) }}</div>
      <div>{{ $t('side_bar_info_service_version', { api_ver: systemServiceVersion, web_ver: ver }) }}</div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'showExitAlert'">
        <div class="dialog__title">{{ $t('numsearch_result_pageback_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('numsearch_result_pageback_desc') }}</div>
        <a class="long-button mt-60" v-on:click="reloadRoute">{{ $t('numsearch_result_pageback_button') }}</a>
      </div>
    </div>
  </div>
  <div v-else class="sidebar">
    <div class="sidebar__img" v-bind:style="sidebarImg"></div>
    <div class="sidebar__mask"></div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

import i18n from '../../lang/lang';

export default {
  name: 'Sidebar',
  data() {
    return {
      ver: `v${process.env.VUE_APP_VER}`,
      version: process.env.VUE_APP_VERSION,
      sidebarImg: {
        'background-image': `url(${process.env.VUE_APP_ROUTER_BASE}sidebar_camera.png)`,
      },
      sidebar: process.env.VUE_APP_SIDEBAR.split(', '),
      show: false,
      dialog: {
        type: null,
      },
    };
  },
  computed: {
    unlimitedQuota() {
      return this.$store.state.license.unlimited_quota;
    },
    systemServiceVersion() {
      return this.$store.state.version.service;
    },
    systemDataVersion() {
      return this.$store.state.version.data;
    },
  },
  props: {
    isLogin: Boolean,
  },
  created() {
    if (this.$route.path === '/user/quota' || this.$route.path == '/user/account' || this.$route.path == '/user/search_log_report') {
      this.show = true;
    }
  },
  filters: {
    commaFormat: function (value) {
      return value.toString().replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function (all, pre, groupOf3Digital) {
        return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
      });
    },
    etimeFormat: function (dt) {
      const dtFormat = moment.tz(dt, 'UTC').tz('Asia/Taipei').format('YYYY-MM-DD');
      return i18n.t('side_bar_info_lic_etime', { lic_etime: dtFormat });
    },
  },
  beforeRouteLeave: function (to, from, next) {
    if (this.batchNumberForm.resultData.length > 0 && to.path !== '/user/login') {
      const $ = this;
      $.exitNext = to.path;
      $.dialog.type = 'showExitAlert';
    } else {
      next();
    }
  },
  methods: {
    checkNowPage: function () {
      if (document.getElementById('batch-table')) {
        const $ = this;
        $.dialog.type = 'showExitAlert';
      }
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
    reloadRoute: function () {
      location.reload();
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: relative;
  flex-basis: 20%;
  background-color: #2b012a;
  color: #ffffff;
  overflow-y: scroll;
  padding: 40px 0;
}

.sidebar.console {
  display: flex;
  flex-direction: column;
  background-color: #161616;
  align-items: center;
  text-align: center;
}

.sidebar__img {
  position: absolute;
  width: 100%;
  top: 0;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  padding-bottom: 176%;
}

.sidebar__mask {
  position: absolute;
  width: 100%;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(61, 0, 60, 0.4), #2b012a);
  padding-bottom: 176%;
  z-index: 2;
}

.sidebar__hr {
  width: 50%;
}

.menu {
  position: relative;
  width: 100%;
  height: 6vh;
  line-height: 6vh;
}

.submenu {
  position: relative;
  width: 100%;
  height: 6vh;
  line-height: 6vh;
}

.menu.active {
  background-color: #222;
  font-size: 18px;
  font-weight: 600;
}

.menu.has-sub-active {
  background-color: #222;
  font-size: 18px;
  font-weight: 600;
}

.submenu.active,
.submenu.has-bg {
  background-color: #222;
}

.menu.active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15px;
  background-color: #640061;
}

.submenu.active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15px;
  background-color: #640061;
}

.menu,
.submenu {
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  text-align: left;
}

.submenu__icon {
  position: absolute;
  right: 10px;
  line-height: 6vh;
}

.version {
  width: calc(100% - 40px);
  font-size: 10px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
}

.sidebar__remain {
  font-size: 36px;
  font-weight: 600;
}

.sidebar__text {
  font-size: 18px;
  font-weight: 600;
}

.sidebar__name {
  font-size: 14px;
}

.sidebar__etime {
  font-size: 14px;
}

.sidebar__disk {
  font-size: 14px;
}
</style>
