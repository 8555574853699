<template>
  <div class="site-main">
    <app-header :title="$t('side_bar_menu_search_log_report')"></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div v-if="message.type" class="main-content">
        <full-page-message :type="message.type" :message="message.text"></full-page-message>
      </div>
      <div v-if="!message.type" class="main-content">
        <div class="main-item mt-40">
          <div v-if="status.isLoading" class="main-item mt-30">
            <inline-loading></inline-loading>
          </div>
          <div class="result__block__empty" v-if="resultData != null && resultData.length == 0">
            {{ $t('reportcnt_200_blank') }}
          </div>
          <div class="result__block" v-if="resultData != null && resultData.length > 0">
            <div class="result__block__list result__block__head">
              <div class="result__block__list-item">{{ $t('reportcnt_result_table_date') }}</div>
              <div class="result__block__list-item">{{ $t('query_counts') }}</div>
              <div class="result__block__list-item">{{ $t('file_status') }}</div>
              <div class="result__block__list-item">{{ $t('log_file') }}</div>
            </div>
            <div class="result__block__list result__block__content" v-for="(item) in resultData" v-bind:key="item.id">
              <div class="result__block__list-item">{{ item.month_year }}</div>
              <div class="result__block__list-item">{{ item.record_count }}</div>
              <div class="result__block__list-item">{{ $t(item.status) }}</div>
              <div class="result__block__list-item">
                <div v-if="item.status === 'succeeded' && item.record_count > 0">
                  <download-bottom :downLoadApi="getSearchLogMonthlyReportDownload" :item="item"></download-bottom>
                </div>
                <div v-else>
                  <div class="result__block_list-item_center">{{ $t('download') }} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-item mt-40">
          <template>
            <div v-text="$t('search_log_report_notes')" style="white-space: pre-wrap;"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetSearchLogMonthlyReportDownload, apiGetSearchLogMonthlyReports } from '../api.js';
import Header from './base/header.vue';
import InlineLoading from './partials/InlineLoading.vue';
import Sidebar from './base/sidebar.vue';
import FullPageMessage from './partials/FullPageMessage.vue';
import DownLoadBottom from './partials/DownLoadBottom.vue';

export default {
  name: 'UserSearchLogReport',
  data() {
    return {
      resultData: null,
      message: {
        type: null,
        text: null,
        apiError: null,
      },
      status: {
        isLoading: false,
      },
    };
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
    'inline-loading': InlineLoading,
    'full-page-message': FullPageMessage,
    'download-bottom': DownLoadBottom,
  },
  computed: {},
  created() {
    this.getSearchLogMonthlyReports();
  },
  methods: {
    getSearchLogMonthlyReports: function () {
      const $ = this;
      $.status.isLoading = true;
      $.message.apiError = null;
      $.resultData = null;

      apiGetSearchLogMonthlyReports()
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.resultData = res.res;
          } else {
            $.message.apiError = $.$i18n.t(`api_error.${res.msg}`);
          }

          $.status.isLoading = false;
        })
        .catch(function (error) {
          $.message.type = 'APIError';
          $.message.text = error;
        });
    },
    getSearchLogMonthlyReportDownload: function (item) {
      return apiGetSearchLogMonthlyReportDownload({ id: item.id })
    },

  },
  filters: {},
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  max-width: 480px;
}

.result__block {
  background-color: #ffffff;
  padding: 30px 40px;
}

.result__block__empty {
  padding: 30px 40px;
  font-size: 14px;
  color: #aeaeae;
  text-align: center;
}

.result__block__list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.result__block__list-item {
  flex-basis: 50%;
}

.result__block__head {
  height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.result__block__content {
  height: 30px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

.form__error {
  text-align: left;
}

.result__block_list-item_center {
  height: 20px;
  width: fit-content;
  padding: 0 20px;
  line-height: 18px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
}
</style>
